<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff" :xx="xx"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft" :leftlist="leftlist" :oneList="ph"></MyLeft>

        <div class="c-right">
          <div class="c-r-top">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
            <el-button type="primary">点击搜索</el-button>
          </div>
          <div class="sousuo">关键词:</div>
          <div class="lista">
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词aadasdsdasdasd1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
            <div class="l-one">热词1</div>
          </div>







        </div>

        <MyRight :topleft="topleft" :leftlist="leftlist" :oneList="ph"></MyRight>


      </div>

      <div class="c-all allnew">
        <div class="c-right">
          <div class="list">
            <div class="l-one" v-for="(item,index) in know">

              <div class="img">
                <img  src="../../assets/my/ban.png"/>
              </div>

              <div class="l-left">
                <div class="l-nr">
                  <div class="l-n-bt">{{item.name}}</div>
                  <div class="l-n-nr">简介简介简介简介简介简介简介简简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介</div>
                  <div class="lll">
                    <div class="lll-list pi1"><img src="../../assets/my/yanjing.png" />{{item.number}}</div>
                    <div class="lll-list pi1"><img src="../../assets/my/shijian1.png" />{{item.time}}</div>
                    
                    <div class="lll-list pi2"><img src="../../assets/my/yanjing2.png" />{{item.number}}</div>                    
                    <div class="lll-list pi2"><img src="../../assets/my/shijian2.png" />{{item.time}}</div>
                  </div>
                 <div class="zxr" >
                    <span>最新查询人</span>
                    <div class="pepolist">
                      <div class="pepo" v-for="item1 in item.jsaccessrecords">
                        <img  :src="'https://viki.js-exp.com/public'+item1.jsusers.avatar" />
                        <div>{{item1.jsusers.nickname}}</div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="l-right shoucang">
                 <img v-if='item.is_ok==false' @click="shoucang(item.id,index)" src="../../assets/images/wsc.png" />
                 <img v-if='item.is_ok==true' @click="qxshoucang(item.id,index)" src="../../assets/images/ysc.png" />
               </div>
             <div class="l-right ">
                <el-button type="primary">查看详情</el-button>
              </div>
            </div>
          </div>




        </div>



      </div>

    </div>


  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';


 import {
    getmessageall
  } from 'network/my.js';


 import {
    getoneList,
    getjsknow,
    getshoucang,
    getpaiHang,
    gettuiJian1,
    gethotCi
  } from 'network/examination.js';


  import 'element-ui/lib/theme-chalk/index.css';
  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import MyLeft from "views/examination/ChildComps/MyLeft.vue";
  import MyRight from "views/examination/ChildComps/MyRight.vue";

  import Page from "components/content/page/Page.vue";
  import Null from "components/content/errornull/null.vue";
  export default {
    name: 'Search',
    data() {
      return {
        input:'',
        ph:[],
        oneList:[],
        items:[
        ],
        litems:[
        ],
        page:0,
        total:'',
        per_page:'',
        current_page:'',
        last_page:'',
        xx:5,
        know:[],
        zt: 0,
        // shzt: 1, //shzt 0= 待审核,1=已驳回
        topleft:0,//左边
        leftlist:[],
        message:[],
        radio1: 1,
        textarea2: '',
        options: [{
          value: '城市',
          lable: '0'
        }, ],
        name: '',
        leftlist:[],
        grxx: {},
      }
    },
    setup() {
      const top = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query,id) => {
        // 项目:0=会议,1=论坛,2=技术成果库,3=需求商机,4=投融资
        if(query=='0'){
          path='';
        }else if(query=='1'){
          path='';
        }else if(query=='2'){
          path='';
        }else if(query=='3'){
          path='';
        }else if(query=='4'){
          path='';
        }
        var list={
          id:id,
        }
        router.push({
          path: path,
          query: list || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },



  watch: {
      // 方法1
      '$route'(to, from) { //监听路由是否变化
        console.log(this.$route.params);
        if (this.$route.params.articleId) { // 判断条件1  判断传递值的变化
          //获取文章数据
        }
      },
      //方法2
      '$route'(to, from) {
        console.log(to);
        if (to.path == "/") { /// 判断条件2  监听路由名 监听你从什么路由跳转过来的

          var url = window.location.hash.split("?")[1]; //?id="123456"&name="www";
          url = '?' + url;
          console.log(window.location.search);
          var object = {};
          if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
          {
            var str = url.substr(1); //得到?后面的字符串
            var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
            for (var i = 0; i < strs.length; i++) {
              object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
            }
          }
          console.log(object);
          // var query=window.location.search.substring(1);
          var id = object['id'];
          this.topleft = id;
          this.getdate(id);

        }
      },
    },

    mounted() {
      var cid='1,2,';
      cid=cid.substring(0,cid.length-1)
      console.log(cid);
      var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // var url = window.location.href;
      console.log(url);
      var object = {};
      if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      {
        var str = url.substr(1); //得到?后面的字符串
        var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
        for (var i = 0; i < strs.length; i++) {
          object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
        }
      }
      console.log(object);
      var lx = object['lx'];

      this.zt = lx;
      // this.getdata(this.zt);
      this.paiHang();
      this.tuiJian();
      this.hotCi();
      // this.list();
    },


    methods: {
      // list(){
      //   var that = this;
      //   var data = {
      //     type:0
      //   };
      //   getshoucang(data).then(res => {
      //     // that.know=res.data;
      //     console.log(res);
      //     console.log('ccc');
      //   }).catch(err => {})

      // },
      shoucang(id,index){
        this.know[index]['is_ok']=true;

         // const h = this.$createElement;
         this.$notify({
            // title: '提示',
            message: '收藏成功'
          });

        var that = this;
        var data = {
          id:id,
          type:0
        };
        getshoucang(data).then(res => {

        }).catch(err => {})


      },
      qxshoucang(id,index){
        this.know[index]['is_ok']=false;
        // const h = this.$createElement;
        this.$notify({
           // title: '提示',
           message: '取消收藏成功'
         });

        var that = this;
        var data = {
          id:id,
          type:1
        };
        getshoucang(data).then(res => {

        }).catch(err => {})


      },

       paiHang(){
          var that = this;
          var data = {
          };          getpaiHang(data).then(res => {
            console.log(res);
            console.log('ph');
            that.ph=res.data;
            that.know=res.data;
          }).catch(err => {})
       },
       tuiJian(){
          var that = this;
          var data = {
          };
          gettuiJian1(data).then(res => {
            console.log(res);
          }).catch(err => {})
       },
       hotCi(){
          var that = this;
          var data = {
          };
          gethotCi(data).then(res => {
            console.log(res);
          }).catch(err => {})
       },
      getdate(id){
        var that = this;
        var data = {
          id:id
        };
        getjsknow(data).then(res => {
          console.log(res);
          console.log('ppp');
          this.know=res.data;
        }).catch(err => {})

      },


      left(){
        var that = this;
        var data = {
        };
        getJspaper(data).then(res => {
          console.log(res);
          that.leftlist=res.data;
        }).catch(err => {})

      },



      listcount(page){
        this.page=page;
        this.getdata(this.zt);
      },


      getdata(lx){

        console.log(this.grxx.id);
        console.log('jll');

        var that = this;
        var data = {
          status:lx,
          uid:this.grxx.id,
          page:this.page,
          sl:8
        };
        getmessageall(data).then(res => {

          // console.log('zx');
          // console.log(res);
          // that.message=res.data.message;

        that.message=res.data.message.table.data;

        that.total=res.data.message.table.total;
        that.per_page=res.data.message.table.per_page;
        that.current_page=res.data.message.table.current_page;
        that.last_page=res.data.message.table.last_page;
        that.items=res.data.message.items;
        that.litems=res.data.message.litems;





        }).catch(err => {})
      },


      grxxff(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
        this.getdata(this.zt);
      },
      qh(zts){
        this.zt=zts;
        this.page=0;
        this.getdata(zts);
      },
      fanhui() {
        window.history.go(-1);
      }
    },
    components: {
      Head,
      Foot,
      Left,
      Page,
      Null,
      MyRight,
      MyLeft

    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    min-height: 100vh;

    .c-all {
      // width: var(--allWidth);
      // width: 98%;
      // margin: 0 auto;
      // margin-top: 1.275rem;
      // display: flex;

      width: 98%;
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;
      justify-content: space-between;


      .c-right {
        margin-left: 0rem;
        background: #fff;
        width: 58%;
        margin-bottom: 0rem;
        .sousuo{
           font-size: 1rem;
           background: #898989;
           color: #fff;
           padding: 11px;
        }
        .c-r-top{
          display: flex;
          padding: 2rem 1rem;
          justify-content: center;
          .el-input{
            width:80%;
            margin-right: 3%;
          }
        }
        .c-top {
          font-weight: bold;
          padding:0 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }
          .zhongjian{
            display: inline-block;
            margin-left: 16rem;
            div{
               display: inline-block;
               width: 4.875rem;
               padding: 1.275rem 0rem;
               text-align: center;
               margin: 0 2rem;
               cursor: pointer;
            }
            .zs{
              border-bottom: 0.125rem solid #2E9BD6;
            }
          }
          span {
            vertical-align: middle;
          }
        }
        .list{
            // padding: 1.875rem;
            .l-one{
              border-bottom: 0.0625rem solid #E8E8E8;
              padding: 0.8rem 0.8rem;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              position: relative;
              cursor: pointer;
              .l-left{
                width: 100%;
                display: flex;
                img{
                  width: 4rem;
                  height: 4rem;
                  border-radius: 50%;
                  margin-right: 0.625rem;
                }
                .l-nr{
                  .l-n-bt{
                    font-weight: bold;
                    margin-bottom: 0.75rem;
                    margin-top: 0.5rem;
                    font-size: 1.2rem;
                  }
                  .l-n-nr{
                    // white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    font-size: 0.8rem;
                    color: #888888;
                  }
                }
              }
              .shoucang{
                position: absolute;
                top: 0.825rem;
                right: 0.825rem;
                // display: inline-block!important;
              }
              .l-right{
                  position: absolute;
                  bottom: 0.825rem;
                  right: 0.825rem;

                display: flex;
                align-items: center;
                color: #999999;
                img{
                  width: 1.75rem;
                  margin-right: 0.625rem ;
                }

              }
            }
        }

      }
    }

  }
 .shoucang{
    display: flex!important;
    height: 0.625rem;
  }

  .allnew{
    width: 98%!important;
    .c-right{
      width: 100%!important;
      margin:0 auto;
    }
  }
  @media screen and (max-width: 1300px) {

     .c-all{
       width: 95%!important;
       margin: 0 auto;
       flex-wrap: wrap;
     }
     .c-right{
       width: 100%!important;
       margin: 0 auto!important;
     }

     .lefts{
       width: 100%;
       margin-bottom: 0!important;
     }

     .c-right{
      .center{
           margin: 0rem !important;
           padding: 0.75rem!important;
      }
     }

     .c-bt{
       display: flex;
     }
     .c-lt{
       padding: 0.65rem 0!important;
       width: 36%;
       text-align: center;
       margin-bottom: 0.625rem;
     }
     .c-right{
       .c-top{
         padding: 0.8rem!important;
         .zhongjian{
           margin: 0!important;
           padding: 0;
           display: flex!important;
           .z-one{
             padding: 0.675rem 0rem!important;
             width: 60%!important;
             font-size: 0.95rem!important;
             margin: 0!important;
             margin-bottom: 0.625rem!important;
           }
         }
       }
     }
    .l-one{
      cursor: pointer;
      flex-wrap: wrap;
      .l-left{
        width: 100%!important;
      }
      .l-right{
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
      }

    }



  }

 .lll{
    display: flex;
    align-items: center;
    margin-top: 0.425rem;

    .lll-list{
      display: flex;
      // align-items: center;
      font-size:0.8rem;
      margin-right: 0.825rem!important;
      color:#888;
      img{
        width: 0.8rem!important;
        height: 0.8rem!important;
        margin-right: 0.125rem!important;
      }
    }
  }
  .zxr{

    font-size:0.7rem;
    margin-top: 0.425rem;

    .pepolist{
       display: flex;
       .pepo{
         text-align: center;
         margin-top: 0.625rem;
         margin-right: 0.625rem;
       }
       img{
         font-size:0.7rem;
         width: 2rem!important;
         height: 2rem!important;
         margin-right: 0.125rem!important;
       }

    }

  }
  .lista{
    width:100%;
    padding:10px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    .l-one{
      cursor: pointer;
      width: 104px;
      margin-top: 11px;
      font-size: 13px;
    }
  }

  .img{
    width: 21%;
    img{
      width: 100%;
    }
  }
  .l-left{
    width: 77% !important;
    margin-left: 1.5%;
  }
  .pi2{
    display: none;
  }
</style>

import axios from "axios";


//暴露一个方法
export function request(config) {
  //创建一个实例
  // console.log(config);
  const instance= axios.create({
    baseURL:'https://viki.js-exp.com',
    timeout:5000,//请求时间 超时时间  

  })

  //请求拦截 设置头信息
  instance.interceptors.request.use(config=>{
    //如果有一些借口 需要认证才可以访问，就在这里统一设置 token
     // console.log(config);
     // console.log('1');
    //直接放行
    return config;
  },err=>{
    //错误
  });

  //响应拦截

  instance.interceptors.response.use(res=>{
    console.log(res);
    // console.log('2');
    // return '2';

    return res;
  },err=>{
    //如果有错误这里 会处理 显示错误信息
    // console.log(err);
    // console.log('3');
    //如果需要授权才可以访问的接口 统一跳转登录

    // return err;
  })
  // console.log(config);

  return instance(config);
}

<template>
  <div class="all" id="all">
    <Head :top="top" :grxxff="grxxff" :xx="xx"></Head>
    <div class="bj">
        <span class="overlayGradient "></span>
        <div class="zong">
          <div class="huanhang"></div>

          <div class="wm"></div>

          <div class="sousuo">
            <!-- <el-input v-model="input"></el-input> -->
            <input  @keyup.enter="sousuo()"   @keyup.enter.native="sousuo()" v-model="nametext" class="inp" placeholder="请输入搜索内容" />
            <img class="dw" src="../../assets/cloud/sousuo.png"/>
            <img class="dw1" src="../../assets/my/sss.png" @click="sousuo"/>
          </div>

          <div class="gjc" :class="tabbarBoxFixed1==false?'':'xxa'">
            <div class="g-one" v-for="(item,index) in hot" @click="weizhi(item.name)">
              <img src="../../assets/my/reci.png"  v-if="index<=2" />
              <img src="../../assets/my/pm1.png"  v-else />
              <div>{{item.name}}</div>
              <div class="jp" v-if="index<=2">
                <div class="numdw">
                  <img src="../../assets/my/dyi.png" v-if="index==0"  />
                  <img src="../../assets/my/dier.png" v-if="index==1"  />
                  <img src="../../assets/my/dsan.png" v-if="index==2"  />
                  <div class="num">{{index+1}}</div>
                </div>
              </div>
            </div>


          </div>
          <div class="tuijian" :class="tabbarBoxFixed2==false?'':'xxa'" >
            <div class="t-one" v-for="item in tuijianlist"  @click="goTo('know',item.id)" >
              <img  src="../../assets/cloud/sousuo.png" />
              <div>{{item.name}}</div>
            </div>

          </div>



          <div class="list" :class="tabbarBoxFixed?'bslist':''" id="list">
            <div class="w94">
              <!-- <list :know="know" :type='0'></list> -->
              <list :know="know" :know1="know1" :know2="know2" @page="getdatapage($event)" :type='0' ></list>
              <el-empty v-if="know.length==0 && tabbarBoxFixed" description="搜索的知识库不存在"></el-empty>
            </div>
          </div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>

          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>
          <div class="huanhang"></div>




        </div>

    </div>

  </div>
</template>

<script>

import {ref,onMounted} from 'vue';
import {getHome,getbanner} from 'network/home.js';
import {useRouter} from 'vue-router';
import List from "views/examination/ChildComps/List.vue";
 import {
    getpaiHang,
    gethotCi,
    getshoucang,
    getguanjianci,
    gettuiJian1
  } from 'network/examination.js';


import Head from "components/content/headder/Head.vue";

export default {
  name: 'Search',
  data() {
    return {
      nametext:'',
      xx:4,
      hot:[],
      know:[],
      know1:[],
      know2:[],
      page:0,
      tuijianlist:[],
      input:'',
      tabbarBoxFixed:false,
      tabbarBoxFixed1:false,
      tabbarBoxFixed2:false,
    }
  },
  setup(){
    const top=2;
    const router=useRouter();
    const databa=ref([]);
    const ab='sb';
    console.log(ab);
    onMounted(()=>{

      getHome().then(res=>{
        console.log(res.data.data);
        databa.value=res.data;
        console.log(databa);

      }).catch(err=>{

      })


    })
    //跳转方法
    var that = this;
    const goTo = (path, fl, lx) => {
      if (lx == 1) {
        var query = {
          fl: fl
        }
      } else if (lx == 2) {
        sessionStorage.setItem("sousuo", fl); //存储
        var query = {
          sousuo: fl
        }
      } else {
        var query = {
          id: fl
        }
      }
      router.push({
        path: path,
        query: query || {}
      })
    }


    return { databa,ab,goTo,top }
  },

  methods: {


    getdatapage(event){
      console.log(event);
        sessionStorage.setItem("page",event);
      this.page=event;
      this.sousuo();
    },

    sousuo(){
      document.documentElement.scrollTop = 220;
      var text=this.nametext;
      console.log(text);
      console.log('111111111111111111');
      if(!text){
        //请输入搜索词
        this.$notify({
          type: 'error',
          message: '请输入搜索词',
          offset: 60
         });
         return false;
      }
      var that = this;
      var data = {
        content:text,
        page:this.page
      };

      sessionStorage.setItem("page",0);
      this.page=sessionStorage.getItem("page");



      console.log(data);
      getguanjianci(data).then(res => {
        console.log(res);
        console.log('ph1');
        this.know=res.data.table.data;
        this.know1=res.data;
        this.know2=res.data.table;
      }).catch(err => {})

    },
    weizhi(nametext){

      this.nametext=nametext;
      this.sousuo();
    },



    shoucang(id,index){
      this.know[index]['is_ok']=true;

       // const h = this.$createElement;
       this.$notify({
          // title: '提示',
          type: 'success',
          message: '收藏成功',
           offset: 60
        });

      var that = this;
      var data = {
        id:id,
        type:0
      };
      getshoucang(data).then(res => {

      }).catch(err => {})


    },
    qxshoucang(id,index){
      this.know[index]['is_ok']=false;
      // const h = this.$createElement;
      this.$notify({
         // title: '提示',
        type: 'success',
         message: '取消收藏成功',
          offset: 60
       });

      var that = this;
      var data = {
        id:id,
        type:1
      };
      getshoucang(data).then(res => {

      }).catch(err => {})


    },

    paiHang(){
       var that = this;
       var data = {
       };
       getpaiHang(data).then(res => {
         console.log(res);
         console.log('ph');
         that.ph=res.data.table.data;
         that.know=res.data.table.data;
       }).catch(err => {})
    },

    hotCi(){
       var that = this;
       var data = {
       };
       gethotCi(data).then(res => {
          that.hot=res.data;
       }).catch(err => {})
    },
    tuijian(){
       var that = this;
       var data = {
       };
       gettuiJian1(data).then(res => {
          that.tuijianlist=res.data.table.data;
       }).catch(err => {})
    },

    //滚动事件
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var offsetTop = document.querySelector('#list').offsetTop // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
      console.log(scrollTop,offsetTop)
      // if(offsetTop>=578){

        if(scrollTop>=130){
          this.tabbarBoxFixed1 = true
        }else{
          this.tabbarBoxFixed1 = false
        }
        if(scrollTop>=220){
          this.tabbarBoxFixed2 = true
        }else{
          this.tabbarBoxFixed2 = false
        }

      if(scrollTop>=130){
        this.tabbarBoxFixed = true
      }else{
        this.tabbarBoxFixed = false
      }
      // if (scrollTop > offsetTop) {
      //   this.tabbarBoxFixed = true
      //   // console.log('1');
      // } else {
      //   this.tabbarBoxFixed = false
      //   // console.log('2');
      // }
    },
  },
  mounted() {

    // var url = window.location.hash.split("?")[1]; //?id="123456"&name="www";
    // url = '?' + url;
    // console.log(url);
    // var object = {};
    // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
    // {
    //   var str = url.substr(1); //得到?后面的字符串
    //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
    //   for (var i = 0; i < strs.length; i++) {
    //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
    //   }
    // }




    // 向页面添加股东事件
    window.addEventListener('scroll', this.handleScroll, true);

    this.hotCi();
    this.tuijian();
    var name=this.$route.query.name;


    sessionStorage.setItem("page",0);
    this.page=sessionStorage.getItem("page");

    if(name){
      this.nametext=name;
      this.sousuo();
    }else{
      this.paiHang();
    }
  },
  destroyed() { //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener('scroll', this.handleScroll)
  },

  components:{
    Head,
    List
  },
}
</script>
<style lang="scss" scoped="scoped">
  .a-cen{
    width: 100vw;
    background: #fff;
    padding: 1rem 0;
    .center{
      width: var(--allWidth);
      margin: 0 auto;
    }
  }
  .zong{
    z-index: 190!important;
    position: relative;
    min-height: 94vh;
    .huanhang{
      height: 5vh;
    }
    .gjc{
       display: flex;
       align-items: center;
       color: #fff;
       width: 90%;
       margin: 0 auto;
       margin-top: 1.5625rem;
       justify-content: center;
       flex-wrap: wrap;
      .g-one{
         text-align: center;
         padding: 10px 27px;
         cursor: pointer;
         font-size: 0.8rem;
         position: relative;
         margin: 0 8px;

        img{
          width: 3.125rem;
        }

        .jp{
          position: absolute;
          top: 0;
          right:0;
          img{
            width: 2.3rem;
          }
          .numdw{
            position: relative;
            .num{
              position: absolute;
              top: 57%;
              left: 51%;
              transform: translate(-50%, -50%);
              color: #555;
            }
          }

        }
      }

      .g-one:hover{
        background: rgba(255,255,255,0.3);
        border-radius: 5px;
      }
    }

    .tuijian{
      display: flex;
      align-items: center;
      color: #fff;
      width: 90%;
      margin: 0 auto;
      margin-top: 1.5625rem;
      // justify-content: center;
      flex-wrap: wrap;
      background: rgba(0,0,0,0.4);
      padding: 0.825rem 0.825rem 0 0.825rem;
      font-size: 0.8rem;
      border-radius: 0.3375rem;

      .t-one{
        display: flex;
        align-items: center;
        padding: 0.3rem 0.625rem;
        width: 10%;
        overflow: hidden;
        img{
          width: 1rem;
          margin-right: 0.625rem;
        }
        cursor: pointer;
        margin-bottom: 0.625rem;
      }
      .t-one:hover{
        background: rgba(255,255,255,0.3);
        border-radius: 5px;
      }

    }
    // .wm{
    //   position: sticky;
    //   top: 70px;
    // }



    .sousuo{
      // width: 68%;
      // margin: 0 auto;

      // z-index: 999999999;
      // top: 4.625rem;
      // width: 60%;
      // border-radius: 59px!important;
      // background-color: #f5f5f5; /*设置输入框背景颜色，可以根据需求修改*/
      // margin: 0 auto;
      // text-align: center;
      // padding: 5px;
      // position: sticky;
      // top: 4.975rem;

        width: 68%;
        margin: 0 auto;
        z-index: 190;
        top: 4.625rem;
        width: 60%;
        border-radius: 59px !important;
        background-color: #0000001c;
        margin: 0 auto;
        text-align: center;
        padding: 5px;
        position: sticky;
        top: 4.975rem;
        padding: 13px;



         .dw{
           position: absolute;
           top: 50%;
           left: 5%;
           transform: translate(-50%,-50%);
           width: 1.25rem;
         }
         .dw1{
           position: absolute;
           top: 50%;
           right: 3%;
           transform: translate(-50%,-50%);
           width: 1.555rem;
           cursor: pointer;
         }
       .inp {
          border: none; /*去掉输入框边框*/
          background-color: #f5f5f5; /*设置输入框背景颜色，可以根据需求修改*/
          // padding: 5px 30px!important; /*设置输入框内边距，增加输入体验*/
          border-radius: 59px; /*设置输入框边框弧度，使其更加美观*/
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /*添加投影，增强视觉效果*/
          transition: all .3s ease; /*添加动画效果，当我们鼠标移入时有更流畅的过渡效果*/
          width: 98%;
          padding: 0.875rem 3.75rem!important;
          margin: 0 auto;

        }
       .inp:focus {
          outline: none; /*去除输入框选中时出现的蓝色边框*/
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /*增加投影效果，使输入框更加明显*/
        }



    }
  }
  .list{
    padding: 0.875rem;
    width: 100%;
    // height: 120vh;
    margin-top: 1.875rem;
    position: sticky;
    top: 10.6875rem;
    // background: #fff;
    margin: 0 auto;
    margin-top: 1.275rem;
    min-height: 88vh;
  }
  .bslist{
    background: #fff;
  }
  .bj{
     width: 100%;
     min-height: 100vh;
     background: url(https://viki.js-exp.com/public/img/bjd498e236.jpg) no-repeat;
     background-size: 100%;
     background-attachment: fixed;


     // background-position: center center;
     // background-repeat: no-repeat;
     // background-size: cover;
     // height: 101%;
     // margin: -1px 0px 0px -1px;
     // object-fit: cover;
     // padding: 0px;
     // position: absolute;
     // width: 101%;


     .overlayGradient{
        height: 160vh;
        opacity: 0.6;
        position: fixed;
        top: 0px;
        transition: opacity 0.3s linear 0s;
        width: 100%;
        z-index: 99;
        // background: #fff;
        background-image: radial-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), radial-gradient(rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.3) 166%);
     }
  }
  .el-input{
    .el-input__wrapper{
      padding: 8px!important;
      border-radius: 59px!important;
    }
  }

  .list{
      // padding: 1.875rem;
      .l-one{
        background: #ffffff66;
        // background: #fff;
        border-bottom: 0.0625rem solid #E8E8E8;
        padding: 0.8rem 0.8rem;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        align-items: flex-start;
        .l-left{
          // width: 100%;
          display: flex;
          img{
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            margin-right: 0.625rem;
          }
          .l-nr{
            .l-n-bt{
              font-weight: bold;
              margin-bottom: 0.75rem;
              margin-top: 0.5rem;
              font-size: 1.2rem;
            }
            .l-n-nr{
              // white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              font-size: 0.8rem;
              color: #000;
            }
          }
        }
        .shoucang{
          position: absolute;
          top: 0.825rem;
          right: 0.825rem;
          // display: inline-block!important;
        }
        .l-right{
            position: absolute;
            bottom: 0.825rem;
            right: 0.825rem;

          display: flex;
          align-items: center;
          color: #999999;
          img{
            width: 1.75rem;
            margin-right: 0.625rem ;
          }

        }
      }
  }

  .pi2{
     display: none!important;
   }


  .l-one:hover{
      // background: #064addd6;
      background: rgb(36 88 201 / 81%);
      color: #fff;
      .l-n-nr{
        color: #fff!important;
      }
      .lll-list{
        color: #fff!important;
      }

      .pi2{
        display: inline-block!important;
      }
      .pi1{
        display: none!important;
      }

  }
  .l-right>img{
    position: absolute;
    top: -0.6875rem;
  }
.l-one{
  width: 91.4%;
  margin: 0 auto;
      cursor: pointer;
      flex-wrap: wrap;
      .l-left{
        // width: 100%!important;
      }
      .l-right{
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
      }

    }
 .zxr{

    font-size:0.7rem;
    margin-top: 0.425rem;

    .pepolist{
       display: flex;
       .pepo{
         text-align: center;
         margin-top: 0.625rem;
         margin-right: 0.625rem;
       }
       img{
         font-size:0.7rem;
         width: 2rem!important;
         height: 2rem!important;
         margin-right: 0.125rem!important;
       }

    }

  }
  .lll{
     display: flex;
     align-items: center;
     margin-top: 0.425rem;

     .lll-list{
       display: flex;
       // align-items: center;
       font-size:0.8rem;
       margin-right: 0.825rem!important;
       color:#000;
       img{
         width: 0.8rem!important;
         height: 0.8rem!important;
         margin-right: 0.125rem!important;
       }
     }
   }
  .img{
    width: 21%;
    img{
      width: 100%;
    }
  }
  .l-left{
    width: 77% !important;
    margin-left: 1.5%;
  }
   @media screen and (max-width: 1300px) {
     .list{
       margin-top:0.275rem!important;
     }
     .tuijian{
          margin-top: 0.8625rem!important;
       white-space: nowrap;
       display: block!important;
       overflow-x: hidden!important;
       .t-one{
         width: auto!important;
         display: inline-block!important;
         img{
           display:none;
         }
       }
     }
     .gjc{
       margin-top: 0.20rem!important;
       width: 100%!important;
       overflow-x: auto!important;
       display: block!important;
       white-space: nowrap;
     }
     .g-one{
         font-size: 0.8rem!important;
         margin: 0 5px!important;
         padding:0.2rem!important;
         display: inline-block!important;
     }
      .huanhang{
          height: 1vh!important;
      }
      .sousuo{
        width: 92%!important;
      }
      .dw{
        left: 12%!important;
      }
   }
   .xxa{
     transition: 0.1s all;
     opacity: 0;
   }
   .w94{
     width: 94%;
     margin: 0 auto;
   }
   .l-n-nr{
     color:#000;
   }
   .lll-list{
     color:#000!important;
   }
</style>

<template>
  <div class="all" id="all">
    <Head :top="top" :grxxff="grxxff" :xx="xx"></Head>
    <div class="list" :class="tabbarBoxFixed?'bslist':''" id="list">


      <list :know="know" :know1="know1" :know2="know2" @page="getdatapage($event)" :type='1' ></list>
      <el-empty description="暂无收藏" v-if="!know"></el-empty>
 <!--     <block v-for="(item,index) in know"  >

          <div class="l-one"   v-if="item.is_ok==true">
            <div class="img">
              <img  :src="'https://viki.js-exp.com/public/'+item.fimage"/>
            </div>

            <div class="l-left">
              <div class="l-nr">
                <div class="l-n-bt">{{item.name}}</div>
                <div class="l-n-nr">{{item.jianjie}}</div>
                <div class="lll">

                  <div class="lll-list pi1"><img src="../../assets/my/yanjing.png" />{{item.number}}</div>
                  <div class="lll-list pi1"><img src="../../assets/my/shijian1.png" />{{item.time}}</div>

                  <div class="lll-list pi2"><img src="../../assets/my/yanjing2.png" />{{item.number}}</div>
                  <div class="lll-list pi2"><img src="../../assets/my/shijian2.png" />{{item.time}}</div>

                </div>
               <div class="zxr" >
                  <span>最新查询人</span>
                  <div class="pepolist">
                    <div class="pepo" v-for="item1 in item.jsaccessrecords">
                      <img  :src="'https://viki.js-exp.com/public'+item1.jsusers.avatar" />
                      <div>{{item1.jsusers.nickname}}</div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="l-right shoucang">
               <img v-if='item.is_ok==false' @click="shoucang(item.id,index)" src="../../assets/images/wsc.png" />
               <img v-if='item.is_ok==true' @click="qxshoucang(item.id,index)" src="../../assets/images/ysc.png" />
             </div>
            <div class="l-right ">
              <el-button type="primary">查看详情</el-button>
            </div>
          </div>

    </block> -->





    </div>


  </div>
</template>

<script>

import {ref,onMounted} from 'vue';
import {getHome,getbanner} from 'network/home.js';
import {useRouter} from 'vue-router';
import List from "views/examination/ChildComps/List.vue";
 import {
    getcollectList,
    getshoucang
  } from 'network/examination.js';


import Head from "components/content/headder/Head.vue";

export default {
  name: 'Search',
  data() {
    return {
      know:[],
      know1:[],
      know2:[],
      page:0,
      xx:3,
      input:'',
      tabbarBoxFixed:false,
    }
  },
  setup(){
    const top=2;
    const router=useRouter();
    const databa=ref([]);
    const ab='sb';
    console.log(ab);
    onMounted(()=>{

      getHome().then(res=>{
        console.log(res.data.data);
        databa.value=res.data;
        console.log(databa);

      }).catch(err=>{

      })

    })
    //跳转方法
    const goTo=(path,query)=>{
      router.push({
        path:path,
        query:query || {}
      })
    }

    return { databa,ab,goTo,top }
  },

  methods: {
    getdatapage(event){
      console.log(event);
      this.page=event;
      this.collectList();
    },

    collectList(){
       var that = this;
       var data = {
         page:this.page
       };
       getcollectList(data).then(res => {
         console.log(res);
         console.log('ph');
         that.ph=res.data;

         if(that.ph.code=='0001'){
            window.location.href="https://viki.js-exp.com/public/uPqdLSmiBQ.php/index/login";
          }
                
         this.know=res.data.table.data;
         this.know1=res.data;
         this.know2=res.data.table;

       }).catch(err => {})
    },

    //滚动事件
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var offsetTop = document.querySelector('#list').offsetTop // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
      console.log(scrollTop,offsetTop)
      if(offsetTop>=578){
        this.tabbarBoxFixed = true
      }else{
        this.tabbarBoxFixed = false
      }
      // if (scrollTop > offsetTop) {
      //   this.tabbarBoxFixed = true
      //   // console.log('1');
      // } else {
      //   this.tabbarBoxFixed = false
      //   // console.log('2');
      // }
    },
  },
  mounted() {
    // 向页面添加股东事件
    window.addEventListener('scroll', this.handleScroll, true);
    this.collectList();
  },
  destroyed() { //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener('scroll', this.handleScroll)
  },

  components:{
    Head,
    List
  },
}
</script>
<style lang="scss" scoped="scoped">
  .a-cen{
    width: 100vw;
    background: #fff;
    padding: 1rem 0;
    .center{
      width: var(--allWidth);
      margin: 0 auto;
    }
  }
  .zong{
    z-index: 9999999!important;
    position: relative;
    min-height: 94vh;
    .huanhang{
      height: 5vh;
    }
    .gjc{
       display: flex;
       align-items: center;
       color: #fff;
       width: 90%;
       margin: 0 auto;
       margin-top: 1.5625rem;
       justify-content: center;
       flex-wrap: wrap;
      .g-one{
         text-align: center;
         padding: 10px 27px;
         cursor: pointer;
         font-size: 0.8rem;
         position: relative;
         margin: 0 8px;

        img{
          width: 3.125rem;
        }

        .jp{
          position: absolute;
          top: 0;
          right:0;
          img{
            width: 2.3rem;
          }
          .numdw{
            position: relative;
            .num{
              position: absolute;
              top: 57%;
              left: 51%;
              transform: translate(-50%, -50%);
              color: #555;
            }
          }

        }
      }

      .g-one:hover{
        background: rgba(255,255,255,0.3);
        border-radius: 5px;
      }
    }

    .tuijian{
      display: flex;
      align-items: center;
      color: #fff;
      width: 90%;
      margin: 0 auto;
      margin-top: 1.5625rem;
      // justify-content: center;
      flex-wrap: wrap;
      background: rgba(0,0,0,0.4);
      padding: 0.825rem 0.825rem 0 0.825rem;
      font-size: 0.8rem;
      border-radius: 0.3375rem;

      .t-one{
        display: flex;
        align-items: center;
        padding: 0.3rem 0.625rem;
        width: 10%;
        overflow: hidden;
        img{
          width: 1rem;
          margin-right: 0.625rem;
        }
        cursor: pointer;
        margin-bottom: 0.625rem;
      }
      .t-one:hover{
        background: rgba(255,255,255,0.3);
        border-radius: 5px;
      }

    }
    // .wm{
    //   position: sticky;
    //   top: 70px;
    // }
    .sousuo{
      width: 68%;
      margin: 0 auto;

      z-index: 999999999;
      top: 4.625rem;
         width: 60%;
         border-radius: 59px!important;
         background-color: #f5f5f5; /*设置输入框背景颜色，可以根据需求修改*/
         margin: 0 auto;
         text-align: center;
         padding: 5px;
         position: sticky;
         top: 4.975rem;
         .dw{
           position: absolute;
           top: 50%;
           left: 5%;
           transform: translate(-50%,-50%);
           width: 1.25rem;
         }
         .dw1{
           position: absolute;
           top: 50%;
           right: 3%;
           transform: translate(-50%,-50%);
           width: 1.555rem;
           cursor: pointer;
         }
       .inp {
          border: none; /*去掉输入框边框*/
          background-color: #f5f5f5; /*设置输入框背景颜色，可以根据需求修改*/
          // padding: 5px 30px!important; /*设置输入框内边距，增加输入体验*/
          border-radius: 59px; /*设置输入框边框弧度，使其更加美观*/
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /*添加投影，增强视觉效果*/
          transition: all .3s ease; /*添加动画效果，当我们鼠标移入时有更流畅的过渡效果*/
          width: 98%;
          padding: 8px 60px!important;
          margin: 0 auto;

        }
       .inp:focus {
          outline: none; /*去除输入框选中时出现的蓝色边框*/
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /*增加投影效果，使输入框更加明显*/
        }



    }
  }
  .list{
    padding: 0.875rem;
    width: 100%;
    // height: 120vh;
    margin-top: 1.875rem;
    position: sticky;
    top: 8.6875rem;
    // background: #fff;
    margin: 0 auto;
    margin-top: 1.275rem;
    min-height: 88vh;
  }
  .bslist{
    background: #fff;
  }
  .bj{
     width: 100%;
     min-height: 100vh;
     background: url(http://localhost:8080/public/img/bj.d498e236.jpg) no-repeat;
     background-size: 100%;
     background-attachment: fixed;


     // background-position: center center;
     // background-repeat: no-repeat;
     // background-size: cover;
     // height: 101%;
     // margin: -1px 0px 0px -1px;
     // object-fit: cover;
     // padding: 0px;
     // position: absolute;
     // width: 101%;


     .overlayGradient{
        height: 160vh;
        opacity: 0.6;
        position: absolute;
        top: 0px;
        transition: opacity 0.3s linear 0s;
        width: 100%;
        z-index: 99;
        // background: #fff;
        background-image: radial-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), radial-gradient(rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.3) 166%);
     }
  }
  .el-input{
    .el-input__wrapper{
      padding: 8px!important;
      border-radius: 59px!important;
    }
  }

  .list{
      // padding: 1.875rem;
      .l-one{
        background: #ffffff66;
        // background: #fff;
        border-bottom: 0.0625rem solid #E8E8E8;
        padding: 0.8rem 0.8rem;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        align-items: flex-start;
        .l-left{
          // width: 100%;
          display: flex;
          img{
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            margin-right: 0.625rem;
          }
          .l-nr{
            .l-n-bt{
              font-weight: bold;
              margin-bottom: 0.75rem;
              margin-top: 0.5rem;
              font-size: 1.2rem;
            }
            .l-n-nr{
              // white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              font-size: 0.8rem;
              color: #000;
            }
          }
        }
        .shoucang{
          position: absolute;
          top: 0.825rem;
          right: 0.825rem;
          // display: inline-block!important;
        }
        .l-right{
            position: absolute;
            bottom: 0.825rem;
            right: 0.825rem;

          display: flex;
          align-items: center;
          color: #999999;
          img{
            width: 1.75rem;
            margin-right: 0.625rem ;
          }

        }
      }
  }
  .l-one:hover{
      // background: #064addd6;
      background: rgb(36 88 201 / 81%);
      color: #fff;
      .l-n-nr{
        color: #fff!important;
      }
      .lll-list{
        color: #fff!important;
      }

      .pi2{
        display: inline-block!important;
      }
      .pi1{
        display: none!important;
      }

  }
  .l-right>img{
    position: absolute;
    top: -0.6875rem;
  }
.l-one{
  width: 91.4%;
  margin: 0 auto;
      cursor: pointer;
      flex-wrap: wrap;
      .l-left{
        // width: 100%!important;
      }
      .l-right{
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
      }

    }
 .zxr{

    font-size:0.7rem;
    margin-top: 0.425rem;

    .pepolist{
       display: flex;
       .pepo{
         text-align: center;
         margin-top: 0.625rem;
         margin-right: 0.625rem;
       }
       img{
         font-size:0.7rem;
         width: 2rem!important;
         height: 2rem!important;
         margin-right: 0.125rem!important;
       }

    }

  }
  .lll{
     display: flex;
     align-items: center;
     margin-top: 0.425rem;

     .lll-list{
       display: flex;
       // align-items: center;
       font-size:0.8rem;
       margin-right: 0.825rem!important;
       color:#000;
       img{
         width: 0.8rem!important;
         height: 0.8rem!important;
         margin-right: 0.125rem!important;
       }
     }
   }
  .img{
    width: 21%;
    img{
      width: 100%;
    }
  }
  .l-left{
    width: 77% !important;
    margin-left: 1.5%;
  }
   @media screen and (max-width: 1300px) {
    .l-one{
      background: #fff;
    }
   }
</style>

<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff" :xx="xx"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft" :types='types' :leftlist="leftlist" :oneList="oneList"></MyLeft>
        <div class="c-right" v-show='type==0'>
          <div class="list">
            <div class="l-onea" v-if="jsexamination.name">
              <div class="l-top">
                <div class='l-title'>
                  <el-descriptions :title="jsexamination.name">
                    <el-descriptions-item label="开始考试时间">{{jsexamination.startexaminationtime}}</el-descriptions-item>
                    <el-descriptions-item label="结束考试时间">{{jsexamination.endexaminationtime}}</el-descriptions-item>
                    <el-descriptions-item label="开卷时间">{{jsexamination.opentime}}</el-descriptions-item>
                    <el-descriptions-item label="考试次数（机会）">
                      <el-tag>{{list.number}}次</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="联系地址">规则：{{jsexamination.content}}</el-descriptions-item>
                  </el-descriptions>
                </div>
                <div class='l-hang'></div>
                <div class="l-list">

                  <el-table :data="tableData" style="width: 97%" height="250">
                    <el-table-column prop="time" label="考试时间" width="250">
                    </el-table-column>
                    <el-table-column prop="opentime" label="开卷时间" width="200">
                    </el-table-column>

                    <el-table-column prop="province" label="试卷分数" width="200">
                    </el-table-column>
                    <el-table-column prop="number" label="考试分数" width="120">
                    </el-table-column>
                    <el-table-column prop="level" label="你的评价" width="120">
                    </el-table-column>

                  </el-table>

                </div>
                <div class='l-hang'></div>
                <div class='l-title' v-if="list.number>0">

                  <el-button type="primary" @click="kaoshi(list.id)">开始考试</el-button>
                </div>
              </div>
            </div>
            <div v-if="!jsexamination.name">
              <el-empty :image-size="200" description="点进左侧进入考试"></el-empty>
            </div>
          </div>
        </div>

      <div class="c-right" v-show='type==1'>
        <div class="list">
          <div class="l-onea" v-if="tableData1.length>0">
            <div class="l-top">
              <div class="l-list">


                <el-table :data="tableData1" style="width: 97%" height="250">
                  <el-table-column prop="time" label="考试人" width="250">
                  </el-table-column>
                  <el-table-column prop="level" label="考试试卷" width="120">
                  </el-table-column>
                  <el-table-column prop="time" label="考试时间" width="250">
                  </el-table-column>
                  <el-table-column prop="province" label="试卷分数" width="200">
                  </el-table-column>
                   <el-table-column
                        key="slot"
                        fixed="right"
                        label="操作"
                        width="100">
                        <!-- slot-scope="scope" -->
                        <template #default="scope"  style="    display: block;" >
                          <el-button @click="deleteRow(scope.$index, tableData1)"  type="primary" size="small">批卷</el-button>
                        </template>
                  <!--
                         <template slot-scope="scope">
                                            <el-button type="text" @click="btnrm(scope.row)">删除</el-button>
                          </template>
                        -->
                    </el-table-column>

                </el-table>

              </div>
            </div>
          </div>
          <div v-if="tableData1.length==0">
            <el-empty :image-size="200" description="暂无需要批卷试卷"></el-empty>
          </div>
        </div>
      </div>


      </div>
    </div>


  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';


  import {
    getmessageall
  } from 'network/my.js';


  import {
    getJspaperIn,
    getJspaperIn1
  } from 'network/examination.js';

  import {
    getJspaper,
    getJspaperpd
  } from 'network/examination.js';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";
  import Page from "components/content/page/Page.vue";
  import Null from "components/content/errornull/null.vue";
  export default {
    name: 'My',
    data() {
      return {

          // tableData1: [{
          //         date: '2016-05-02',
          //         name: '王小虎',
          //         province: '上海',
          //         city: '普陀区',
          //         address: '上海市普陀区金沙江路 1518 弄',
          //         zip: 200333
          //       }, {
          //         date: '2016-05-04',
          //         name: '王小虎',
          //         province: '上海',
          //         city: '普陀区',
          //         address: '上海市普陀区金沙江路 1517 弄',
          //         zip: 200333
          //       }, {
          //         date: '2016-05-01',
          //         name: '王小虎',
          //         province: '上海',
          //         city: '普陀区',
          //         address: '上海市普陀区金沙江路 1519 弄',
          //         zip: 200333
          //       }, {
          //         date: '2016-05-03',
          //         name: '王小虎',
          //         province: '上海',
          //         city: '普陀区',
          //         address: '上海市普陀区金沙江路 1516 弄',
          //         zip: 200333
          //       }],

        jsexamination: [],
        items: [],
        litems: [],
        page: 0,
        total: '',
        per_page: '',
        current_page: '',
        last_page: '',
        xx: 2,

        zt: 0,
        // shzt: 1, //shzt 0= 待审核,1=已驳回
        topleft: 0, //左边
        leftlist: [],
        oneList:[],
        types:1,
        message: [],
        radio1: 1,
        textarea2: '',
        type:0,
        options: [{
          value: '城市',
          lable: '0'
        }, ],
        name: '',
        grxx: {},
        list: [],


        tableData: [],
        tableData1: [],

      }
    },
    setup() {
      const top = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query, id) => {
        // 项目:0=会议,1=论坛,2=技术成果库,3=需求商机,4=投融资
        if (query == '0') {
          path = '';
        } else if (query == '1') {
          path = '';
        } else if (query == '2') {
          path = '';
        } else if (query == '3') {
          path = '';
        } else if (query == '4') {
          path = '';
        }
        console.log(query);


        var list = {
          id: query
        }
        router.push({
          path: path,
          query: list || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },


    watch: {
      // 方法1
      '$route'(to, from) { //监听路由是否变化
        console.log(this.$route.params);
        if (this.$route.params.articleId) { // 判断条件1  判断传递值的变化
          //获取文章数据
        }
      },
      //方法2
      '$route'(to, from) {
        console.log(to);
        if (to.path == "/examination") { /// 判断条件2  监听路由名 监听你从什么路由跳转过来的

          var url = window.location.hash.split("?")[1]; //?id="123456"&name="www";
          url = '?' + url;
          console.log(window.location.search);
          var object = {};
          if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
          {
            var str = url.substr(1); //得到?后面的字符串
            var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
            for (var i = 0; i < strs.length; i++) {
              object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
            }
          }
          console.log(object);
          // var query=window.location.search.substring(1);
          var id = object['id'];
          var type = object['type'];
          if(type=='1'){
            this.type='1';
            this.topleft='a';
          }else{
            this.type=0;

            if(id){
              this.topleft = id;
              this.getdate(this.topleft);
            }

          }
          // this.topleft = id;
          // this.getdate(id);

        }
      },
    },

    mounted() {

      var url = window.location.hash.split("?")[1]; //?id="123456"&name="www";
      url = '?' + url;


      console.log(url);
      var object = {};
      if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      {
        var str = url.substr(1); //得到?后面的字符串
        var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
        for (var i = 0; i < strs.length; i++) {
          object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
        }
      }
      console.log(object);
      var lx = object['lx'];
      var id = object['id'];
      var type=object['type'];
      console.log('type');
      console.log(type);
      if(type=='1'){
        this.type='1';
        this.topleft='a';
      }else{
        this.type=0;
      }
      this.topleft = id;
      if(id){
        this.getdate(this.topleft);
      }
      this.getdate1();

      window.onblur = function() {
        return false;
      };
      window.onfocus = function() {
        return false;
      }
      this.left1();

    },


    methods: {
      deleteRow(scope, tableData1){
        console.log(scope);
        console.log(tableData1[scope]);
        this.goTo('/WorkDePi',tableData1[scope]['id']);

      },
      kaoshi(id){
        //判断屏幕宽度
         var that = this;
        const screenWidth = window.innerWidth;
        console.log(screenWidth);
        if(screenWidth<1000){
          that.$alert('需要用电脑考试哦！考试屏幕不能小于1000px;', '提示', {
            showClose: false,
            confirmButtonText: '确认',
            callback: action => {
            }
          });
          return false;
        }

        var data = {
          id:id
        };
        getJspaperpd(data).then(res => {
          console.log(res);
          if(res.data=='1'){
            that.goTo('/WorkDe',id)
          }else{
            that.$alert('不在考试时间内', '提示', {
              showClose: false,
              confirmButtonText: '确认',
              callback: action => {
              }
            });
          }
        }).catch(err => {})



      },
      left1() {
        var that = this;
        var data = {};
        getJspaper(data).then(res => {
          console.log(res);
          that.leftlist = res.data;

          if(!that.topleft){
            that.getdate(that.leftlist[0]['id']);
            that.topleft=that.leftlist[0]['id'];
          }

          // that.topleft=res.data[0]['id'];
        }).catch(err => {})
      },
      getdate(id) {
        var that = this;
        var data = {
          id: id,
        };
        getJspaperIn(data).then(res => {
          console.log(res);
          console.log('abbb');

          that.list = res.data;

          // that.ph=res.data;

          if(that.list.code=='0001'){
             window.location.href="https://viki.js-exp.com/public/uPqdLSmiBQ.php/index/login";
           }

          this.jsexamination = res.data.jsexamination;
          this.tableData = res.data.jspaper;
        }).catch(err => {})

      },

      getdate1(){
        var that = this;
        var data = {
        };
        getJspaperIn1(data).then(res => {
          console.log(res);
          console.log('ccc');
               that.tableData1 = res.data.jspaper;
        }).catch(err => {})

      },
      listcount(page) {
        this.page = page;
        this.getdata(this.zt);
      },

      getdata(lx) {
        console.log(this.grxx.id);
        console.log('jll');
        var that = this;
        var data = {
          status: lx,
          uid: this.grxx.id,
          page: this.page,
          sl: 8
        };
        getmessageall(data).then(res => {
          that.message = res.data.message.table.data;
          that.total = res.data.message.table.total;
          that.per_page = res.data.message.table.per_page;
          that.current_page = res.data.message.table.current_page;
          that.last_page = res.data.message.table.last_page;
          that.items = res.data.message.items;
          that.litems = res.data.message.litems;
        }).catch(err => {})
      },
      grxxff(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
        this.getdata(this.zt);
      },
      qh(zts) {
        this.zt = zts;
        this.page = 0;
        this.getdata(zts);
      },
      fanhui() {
        window.history.go(-1);
      }
    },
    components: {
      Head,
      Foot,
      Left,
      Page,
      Null,
      MyLeft
    },
  }
</script>
<style lang="scss">
  .el-table__header {
    width: 100% !important;
  }

  .el-table__body {
    width: 100% !important;
  }


  .all {
    background: #f5f6fa;
  }

  .center {
    min-height: 100vh;

    .c-all {
      width: var(--allWidth);
      width: 98%;
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 84%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 0 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }

          .zhongjian {
            display: inline-block;
            margin-left: 16rem;

            div {
              display: inline-block;
              width: 4.875rem;
              padding: 1.275rem 0rem;
              text-align: center;
              margin: 0 2rem;
              cursor: pointer;
            }

            .zs {
              border-bottom: 0.125rem solid #2E9BD6;
            }
          }

          span {
            vertical-align: middle;
          }
        }

        .list {

          // padding: 1.875rem;
          .l-one {
            border-bottom: 0.0625rem solid #E8E8E8;
            padding: 1rem 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .l-left {
              width: 60%;
              display: flex;

              img {
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                margin-right: 0.625rem;
              }

              .l-nr {
                .l-n-bt {
                  font-weight: bold;
                  margin-bottom: 0.75rem;
                  margin-top: 0.5rem;
                }

                .l-n-nr {
                  color: #888888;
                }
              }
            }

            .l-right {
              display: flex;
              align-items: center;
              color: #999999;

              img {
                width: 0.875rem;
                margin-right: 0.625rem;
              }

            }
          }
        }

      }
    }

  }


  @media screen and (max-width: 1300px) {
    .l-top{
      overflow-x: hidden;
    }
    .c-all {
      width: 95% !important;
      margin: 0 auto;
      flex-wrap: wrap;
    }

    .c-right {
      width: 100% !important;
      margin: 0 auto !important;
    }

    .lefts {
      width: 100% !important;
    }

    .c-right {
      .center {
        margin: 0rem !important;
        padding: 0.75rem !important;
      }
    }

    .c-bt {
      display: flex;
    }

    .c-lt {
      padding: 0.65rem 0 !important;
      width: 36%;
      text-align: center;
      margin-bottom: 0.625rem;
    }

    .c-right {
      .c-top {
        padding: 0.8rem !important;

        .zhongjian {
          margin: 0 !important;
          padding: 0;
          display: flex !important;

          .z-one {
            padding: 0.675rem 0rem !important;
            width: 60% !important;
            font-size: 0.95rem !important;
            margin: 0 !important;
            margin-bottom: 0.625rem !important;
          }
        }
      }
    }

    .l-one {
      flex-wrap: wrap;

      .l-left {
        width: 100% !important;
      }

      .l-right {
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
      }

    }



  }

  .l-onea {
    .l-title {
      font-size: 16px;
      margin: 15px;

    }

    .l-list {
      margin: 15px;
      font-size: 14px;
      width: 100%;

      table {
        width: 100%;
      }
    }

  }

  .l-hang {
    background: #dedede;
    width: 100%;
    height: 0.125rem;
  }

  .el-table_header {
    width: 100% !important;
  }

  .el-table_body {
    width: 100% !important;
  }


</style>
<style>
  .el-table_header {
    width: 100% !important;
  }

  .el-table_body {
    width: 100% !important;
  }
</style>
